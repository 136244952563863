.form {
    color: white;
    z-index: 3;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1400px;
    width: 100%;
    padding: 0px 25px;
    margin: 0 auto;
    margin-bottom: 150px;
    animation: fadeIn 0.6s ease-in-out 1s 1 forwards normal;
    opacity: 0;
    @keyframes fadeIn {
        0% {opacity: 0; transform: translateY(10px)}
        100% {opacity: 1; transform: translateY(0px)}
      }

    label {
        font-family: var(--font-display);
        font-size: 16px;
        letter-spacing: -0.2px;
        display: flex;
        flex-direction: column;
    }
    .field-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        width: 100%;
        max-width: calc(50% - 10px);
        gap: 3px 15px;
        padding-left: 0px;
        label {
            margin-bottom: 10px;
        }

        .radios {
            display: flex;
            flex-direction: row;
            gap: 10px;
            margin-top: 10px;
            @media screen and (max-width: 768px) {
                flex-direction: column;
            }
        }
        @media screen and (max-width: 1200px) {
            max-width: 100%;
            padding-left: 0px;
            gap: 5px 10px;
        }
    }

    .field {
        display: flex;
        flex-direction: column;
        max-width: calc(50% - 10px);
        width: 100%;
        margin-bottom: 50px;
        input {
            border-radius: 0px !important;
            background-color: transparent;
            border: 0px;
            border-bottom: 1px solid white;
            padding: 10px 5px;
            font-family: var(--font-body);
            color: white;
            outline: none;
            margin-top: 10px;
            background-color: #222;
            &:focus {
                border-bottom: 1px solid #EAB464;
            }
        }
        &.full {
            max-width: 100%;
            textarea {
                background-color: transparent;
                border: 0px;
                border-bottom: 1px solid white;
                min-height: 300px;
                outline: none;
                color: #fff;
                margin-top: 10px;
                background-color: #222;
                padding: 10px 5px;
                font-family: var(--font-body);
                &:focus {
                    border-bottom: 1px solid #EAB464;
                }
                @media screen and (max-width: 1200px) {
                    min-height: 200px;
                }
            }
        }
        @media screen and (max-width: 1200px) {
            max-width: 100%;
        }
    }

    .error {
        color: rgb(253, 119, 119);
        font-family: var(--font-body);
        padding-top: 4px;
    }

    .field-radio {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 37px;
        background-color: transparent;
        color: white;
        border: 1px solid white;
        padding: 2px 20px 2px 20px;
        font-family: var(--font-display);
        letter-spacing: -0.2px;
        font-size: 18px;
        text-align: center;
        line-height: 1;
        border-radius: 50px;
        cursor: pointer;
        @media screen and (max-width: 1500px) {
            font-size: 16px;
        }
        input {
            border-radius: 0px !important;
            cursor: pointer;
            opacity: 0;
            width: 0;
            height: 0;
        }
        &.active {
            border: 1px solid #EAB464;
            color: #EAB464;
        }
        &.inactive {
            border: 1px solid #fff;
            color: #fff;
        }
        transition: 0.3s all ease-in-out;
        &:hover {
            color: #EAB464;
            border: 1px solid #EAB464;
        }
    }

    button {
        background-color: transparent;
        color: white;
        border: 1px solid white;
        padding: 10px 20px 11px 20px;
        font-family: var(--font-display);
        letter-spacing: -0.2px;
        font-size: 20px;
        min-width: 200px;
        text-align: center;
        line-height: 1;
        border-radius: 25px;
        cursor: pointer;
        transition: 0.3s all ease-in-out;
        &:hover {
            color: #EAB464;
            border: 1px solid #EAB464;
        }
    }
    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }
}