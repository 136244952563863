.case-studies {
    margin-bottom: 200px;
    &.extra-space {
        margin-bottom: 100vh;
    }
    @media screen and (max-width: 1024px) {
        margin-bottom: 75px;
    }
    @media screen and (max-width: 650px) {
        margin-bottom: 50px;
    }
}

.study {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:last-child {
        .study-header {
            border-bottom: 1px solid white;
        }
    }
    .study-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 70px;
        border-top: 1px solid white;
        padding-top: 20px;
        padding-left: 20px;
        margin-left: 100px;
        margin-right: 100px;
        padding-bottom: 25px;
        cursor: pointer;
        
        .study-name {
            color: white;
            font-family: var(--font-body);
            font-weight: 600;
            font-size: 50px;
            letter-spacing: -3px;
            display: flex;
            gap: 40px;
            align-items: center;
            transition: 0.3s all ease-in-out;

            .view-project {
                font-size: 20px;
                letter-spacing: -0.8px;
                color: #EAB464;
                transition: 0.3s all ease-in-out;
            }
        }
        .study-categories {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color: white;
            font-family: var(--font-body);
            gap: 15px;
            text-transform: uppercase;
            .category {
                border: 1px solid white;
                padding: 5px 20px;
                border-radius: 50px;
                @media screen and (max-width: 650px) {
                    font-size: 14px;
                }
            }
        }
        @media screen and (max-width: 1400px) {
            flex-direction: column;
            gap: 20px;
            .study-name {
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
        }
        @media screen and (max-width: 1025px) {
            margin-left: 25px;
            margin-right: 25px;
        }
        @media screen and (max-width: 850px) {
            text-align: left;
            align-items: flex-start;
            .study-name {
                align-items: flex-start;
            }
            .study-categories {
                flex-wrap: wrap;
                .category {
                    min-width: 175px;
                    text-align: center;
                }
            }
        }
    }
    .content-area {
        background-color: #111111;
        padding-left: 100px;
        overflow: hidden;        
        .next-project, .previous-project {
            color: white;
            text-transform: uppercase;
            font-size: 20px;
            font-family: var(--font-body);
            font-weight: 600;
        }
        .next-project {
            margin-left: auto;
            margin-right: 100px;
        }
        .previous-project {
            padding-top: 40px;
        }
    }

    .details {
        display: flex;
        flex-direction: row;
        color: white;
        font-family: var(--font-body);
        gap: 70px;
        align-items: center;
        padding-bottom: 40px;
        padding-top: 40px;
        .detail {
            .detail-title {
                font-size: 14px;
                color: #EAB464;
                margin-bottom: 5px;
            }
            .detail-info {
                font-size: 20px;
                letter-spacing: -1px;
            }
            .detail-tools {
                display: flex;
                flex-direction: row;
                gap: 7px;
                .tool {
                    border: 1px solid #EAB464;
                    background-color: #EAB464;
                    color: black;
                    padding: 3px 15px;
                    border-radius: 50px;
                    font-size: 14px;
                }
            }
        }
    }
    &:hover  {
        .study-name {
            color: #EAB464;
            .view-project {
                color: #fff;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        padding: 0px 50px;
        .study-header {
            margin-left: 0px;
            margin-right: 0px;
        }
    }
    @media screen and (max-width: 650px) {
        padding: 0px 25px;
        .study-header {
            .study-name {
                font-size: 38px;
                letter-spacing: -2px;
            }
            padding-left: 0px;
        }
    }
}