.wrapper {
    position: fixed;
    min-height: 100vh;
    width: 100vw;
    background-color: #191919;
    z-index: -1;
    animation: fadeIn 0.6s ease-in-out 1.2s 1 forwards normal;
    opacity: 0;
    .cover {
        object-fit: cover;
        filter: blur(3px);
        height: 100vh;
        width: 100vw;
    }
}

.services-wrapper {
    position: relative;
    height: 700px;
    width: 100%;
    z-index: 1;
    cursor: pointer;
    animation: fadeIn 0.6s ease-in-out 1s 1 forwards normal;
    opacity: 0;
}

.canvas {
    -webkit-tap-highlight-color: transparent;
}

@keyframes fadeIn {
    0% {opacity: 0; transform: translateY(10px)}
    100% {opacity: 1; transform: translateY(0px)}
}