.content {
    position: relative;
    width: 100%;
    padding: 100px 100px;
    @media screen and (max-width: 1024px) {
        padding: 75px 50px;
    }

    @media screen and (max-width: 650px) {
        padding: 50px 25px;
    }

    &.contain {
        width: auto;
        margin-right: 0px !important;
    }

    &.align-left {
        text-align: left;
        margin-right: auto;
        > div {
            margin-right: auto;
        }
    }
    &.align-right {
        text-align: right;
        margin-left: auto;
        > div {
            margin-left: auto;
        }
        @media screen and (max-width: 650px) {
            text-align: left;
        }
    }
    &.align-center {
        text-align: center;
        margin: 0 auto;
        > div {
            margin: 0 auto;
        }
    }

    .number {
        font-family: var(--font-body);
        font-size: 14px;
        color: #e7b163;
        transform: rotateX(90deg);
        perspective: 3000px;
        transform-style: preserve-3d;
        margin-bottom: 5px;
    }
    .title {
        font-family: var(--font-display);
        color: white;
        font-size: clamp(24px, 3.5vw, 38px);
        font-weight: 600;
        max-width: 650px;
        span {
            transform: rotateX(-90deg);
            perspective: 1000px;
            transform-style: preserve-3d;
            color: white;
            display: inline-block;
            transform-origin: 50% 50%;
        }
    }
    .subtitle {
        font-family: var(--font-italic);
        color: white;
        font-size: clamp(30px, 3.5vw, 42px);
        font-style: italic;
        max-width: 650px;
        transform: rotateX(90deg);
        perspective: 3000px;
        transform-style: preserve-3d;

    }
    .paragraphs {
        opacity: 0;
        font-family: var(--font-body);
        font-size: clamp(16px, 3.5vw, 18px);
        color: white;
        margin-top: 10px;
        max-width: 650px;
        line-height: 1.4;
        p {
            margin-top: 10px;
            line-height: 1.6;
        }
    }
}