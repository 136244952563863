.header-wrapper {
    width: 100%;
    height: 100px;
    padding: 30px 100px;
    position: absolute;
    z-index: 2;
    opacity: 0;
    transform: translateY(10px);
    animation: fadeIn 0.6s ease-in-out 1s 1 forwards normal;
    .content-area {
        svg {
            width: 100px;
        }
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
    @media screen and (max-width: 1024px) {
        padding: 30px 50px;
    }
    @media screen and (max-width: 650px) {
        padding: 20px 25px 0px 25px;
    }
}

.nav {
    color: white;
    font-family: var(--font-display);
    display: flex;
    flex-direction: row;
    gap: 55px;
    font-size: 20px;
    border: 1px solid #EAB464;
    border-radius: 80px;
    padding: 12px 30px;
    a {
        transition: 0.3s all ease-in-out;
        text-transform: uppercase;
        padding-bottom: 2px;
        &:hover {
            color: #EAB464;
        }
        &.active {
            color: #EAB464;
        }
    }
    @media screen and (max-width: 1024px) {
        font-size: 16px;
        gap: 35px;
    }
    @media screen and (max-width: 650px) {
        padding: 5px 15px;
    }
}

@keyframes fadeIn {
    0% {opacity: 0; transform: translateY(10px)}
    100% {opacity: 1; transform: translateY(0px)}
  }