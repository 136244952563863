.footer {
    .form-wrapper {
        height: calc(100vh - 40px);
        width: calc(100vw - 80px);
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        bottom: calc(-100vh - 40px);;
        z-index: 2;
        background-color: #191919fd;
        border: 1px solid #EAB464;;
        border-radius: 30px 30px 0px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: 0.5s all ease-in-out;
        &.open {
            bottom: 0;
        }
        .form-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: white;
            overflow: auto;
            padding: 60px;
            .form-title {
                font-family: var(--font-display);
                font-weight: 500;
                font-size: clamp(28px, 6vw, 60px);
                letter-spacing: clamp(-1px, -1vw, -2px);
                line-height: 0.9;
                text-align: center;
                margin-bottom: 50px;
            }
            @media screen and (max-width: 768px) {
                padding: 25px;
                justify-content: flex-start;
                h3 {
                    font-weight: 400;
                    margin-bottom: 30px;
                }
            }
        }
        @media screen and (max-width: 768px) {
            justify-content: flex-start;
            padding-top: 70px;
        }
        @media screen and (max-width: 650px) {
            width: calc(100vw - 30px);
            height: calc(100vh - 100px);
        }
    }
    .contact {
        font-size: clamp(30px, 10vw, 350px);
        font-weight: 500;
        color: white;
        font-family: var(--font-display);
        letter-spacing: clamp(-22px, -0.4vw , -2px);
        cursor: pointer;
        padding: 23px 0px;
        transition: 0.5s all ease-in-out;
        .pointer {
            transform: rotate(30deg);
            width: 150px;
            height: 150px;
            margin: 0px 30px;
            overflow: hidden;
            @media screen and (max-width: 650px) {
                height: 75px;
                width: 75px;
                margin: 0px 15px;
            }
        }
        &:hover {
            color: #EAB464;
            .pointer {
                animation: click 0.75s ease-in-out 0.5s infinite forwards normal;
                @keyframes click {
                    0% {transform: rotate(30deg) scale(1)}
                    50% {transform: rotate(30deg) scale(0.9)}
                    100% {transform: rotate(30deg) scale(1)}
                }
            }
        }
    }
    .cross {
        width: 40px;
        height: 30px;
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;
        transition: 0.5s all ease-in-out;
        &:hover {
            color: #EAB464;
        }
        @media screen and (max-width: 650px) {
            width: 30px;
            height: 30px;
            right: 15px;
            top: 15px;
        }
    }
}

