.title-wrapper {
    position: relative;
    padding-top: 150px;
    .hero-title, .hero-title-secondary {
      font-size: clamp(100px, 20vw, 450px);
      font-weight: 500;
      color: white;
      font-family: var(--font-display);
      letter-spacing: clamp(-27px, -1vw, -2px);
      line-height: 0.8;
      opacity: 0;
      @media screen and (max-width: 1921px) {
        font-size: clamp(65px, 20vw, 300px);
        letter-spacing: clamp(-20px, -1vw, -1px);
      }
    }
    @media screen and (max-width: 769px) {
      max-width: 100vw;
      overflow: hidden;
      .hero-title, .hero-title-secondary {
        line-height: 0.9;
      }
    }
  }

  .cs-wrapper {
    margin-bottom: 10vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    padding-bottom: 20px;
    .hero-title {
      font-size: clamp(16px, 5vw, 35px);
      letter-spacing: clamp(0px, -1vw, -1px);
      padding-top: 20px;
      margin-left: 50px;
      display: inline-block;
      line-height: 1;
      color: #e7b163;
      @media screen and (max-width: 650px) {
        margin-left: 20px;
        padding-left: 10px;
      }
    }
    .hero-title-secondary {
      font-size: clamp(40px, 14vw, 250px);
      letter-spacing: clamp(-14px, -0.75vw, -1px);
      display: inline-block;
      padding-top: 0px;
      text-align: left;
      line-height: 1.1;
      width: auto;
      @media screen and (max-width: 650px) {
        padding-top: 0px;
      }
    }
    @media screen and (max-width: 650px) {
      margin-bottom: 80px;
      padding-top: 100px;
    }
    &.small {
      .hero-title {
        @media screen and (max-width: 650px) {
          margin-left: 0px;
        }
      }
      .hero-title-secondary {
        font-size: clamp(40px, 6.9vw, 150px);
        letter-spacing: clamp(-7px, -0.2vw, -1px);
        max-width: 1400px;
        line-height: 0.95;
        @media screen and (max-width: 650px) {
          max-width: 350px;
          margin-left: -10px;
          line-height: 1;

        }
      }
    }
    @media screen and (max-width: 1400px) {
      margin-bottom: 100px;
    }
    @media screen and (max-width: 1024px) {
      margin-bottom: 50px;
    }

    @media screen and (max-width: 650px) {
      margin-bottom: 2px;
    }
  }

  .hero-title-secondary {
    text-align: right;
    width: 100%;
    opacity: 0;
}
  
  .occupation-title {
    color: white;
    font-family: var(--font-body);
    color: #e7b163;
    font-size: 24px;
    margin-top: 20px;
    letter-spacing: -2px;
    margin-left: 100px;
    margin-bottom: -70px;
    opacity: 0;
    animation: fadeIn 0.6s ease-in-out 1.3s 1 forwards normal;
    @media screen and (max-width: 1024px) {
      margin-left: 50px;
      margin-top: 10px;
      margin-bottom: -40px;
      font-size: 18px;
      letter-spacing: -1px;
    }
    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  @keyframes fadeIn {
    0% {opacity: 0; transform: translateY(10px)}
    100% {opacity: 1; transform: translateY(0px)}
  }