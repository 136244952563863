.services {
    width: 100%;
    padding: 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .container {
        width: calc(100%);
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;
    }

    .services-overlay {
        background-color: #191919;
        font-family: var(--font-body);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding: 40px;
        z-index: 3;
        opacity: 0;
    }

    .service-title {
        color: #fff;
        font-size: 32px;
        font-family: var(--font-display);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 5;
        padding-bottom: 40px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: -0.5px;
        font-weight: 500;
    }

    .service-list {
        color: #fff;
        list-style-type: disc;
        margin-left: 20px;
        margin-top: 10px;
        font-size: 24px;
        letter-spacing: -1px;
        li {
            list-style: disc;
            margin-bottom: 5px;
        }
    }
}