* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  html {
    -webkit-tap-highlight-color: transparent;
  }
  
  body {
    max-width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #191919;
    position: relative;
  }
  
  main {
    min-height: 100vh;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }

  p {
    margin-top: 20px;
  }
  
  
  .body-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 100vh;
    bottom: 0;
    background: linear-gradient(187deg, rgba(25, 25, 25, 1) 8.07%, rgba(72, 191, 227, 1) 36.49%, rgba(89, 120, 211, 1) 71%, rgba(105, 48, 195, 1) 105.51%);
    overflow: hidden;
    &:before {
        content: '';
        background: linear-gradient(180deg, rgba(25, 25, 25, 1) 0%, rgba(25, 25, 25, 0.00) 100%);        
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 400px;
    }
  }

  html.lenis {
    height: auto;
  }
  
  .lenis.lenis-smooth {
    scroll-behavior: auto !important;
  }
  
  .lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
  }
  
  .lenis.lenis-stopped {
    overflow: hidden !important;
    overscroll-behavior: contain;
  }
  
  .lenis.lenis-scrolling iframe {
    pointer-events: none;
  }
